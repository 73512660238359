( function( $ ) {
$( document ).ready(function() {
$('#cssmenu').prepend('<div id="menu-button">Menu</div>');
	$('#cssmenu #menu-button').on('click touch', function(){
		var menu = $(this).next('ul');
		if (menu.hasClass('open')) {
			menu.removeClass('open');
		}
		else {
			menu.addClass('open');
		}
	});
});
} )( jQuery );

$(document).ready(function(){
	$('.hServices').on('click touch', function() {
		$(this).toggleClass('flipCard');
	});

	$('.hContent').on('click touch', function() {
		$(this).toggleClass('openContent');
	});

	$('#direct').on('click touch', function() {
		$('#directText').toggleClass('payText');
		$('#creditText').addClass('payText');
		$('#onlineText').addClass('payText');
		$('#mobileText').addClass('payText');
	});

	$('#credit').on('click touch', function() {
		$('#creditText').toggleClass('payText');
		$('#directText').addClass('payText');
		$('#onlineText').addClass('payText');
		$('#mobileText').addClass('payText');
	});

	$('#online').on('click touch', function() {
		$('#onlineText').toggleClass('payText');
		$('#directText').addClass('payText');
		$('#creditText').addClass('payText');
		$('#mobileText').addClass('payText');
	});

	$('#mobile').on('click touch', function() {
		$('#mobileText').toggleClass('payText');
		$('#directText').addClass('payText');
		$('#creditText').addClass('payText');
		$('#onlineText').addClass('payText');
	});
});