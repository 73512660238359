import "../styles/fonts.css";
import "../styles/style.css";
import "../styles/app.scss";
import "../../../../../node_modules/@fortawesome/fontawesome-free/css/all.min.css";
import "./jquery-1.11.3.min.js";
import "./jquery.cycle.all.js";
import "./responsive-menu.js";
//import "./slideshow.js"
function addFlashMessage(type, message) {
    var element = $("<li class='message " + type + "'><h3>" + type + "</h3><p>" + message + "</p></li>");
    $("#flash").append(element);
    function fadeIn() {
        $(element).addClass('fadeIn');
    }
    window.setTimeout(fadeIn, 500);
    function removeLi() {
        $(element).remove();
    }
    $(element).click(function () {
        $(this).addClass('fadeNow');
        window.setTimeout(removeLi, 1400);
    });
    function fadeOut() {
        $(element).addClass('fadeNow');
        window.setTimeout(removeLi, 1400);
    }
    window.setTimeout(fadeOut, 6000);
}
